import axios from 'axios'
import {
    this_
} from '../main'
// import cookie from 'vue-cookie'
const Qs = require('qs');
import {
    Storage
} from 'utils/utils'
const strore = new Storage();
// const DEV_URL = '/controlapi';//开发环境


const TEST_URL = 'http://183.3.158.22:8599'; //测试服地址
const baseURL = TEST_URL //测试服地址

// const PROD_URL = 'http://scaniov.com/hxapi'; //正式服地址
// const baseURL = PROD_URL //正式服地址


export const http = new axios.create({
    baseURL,
    timeout: 30000
})
http.interceptors.request.use(config => {
    const token = strore.getItem('baas_token');
    if (config.method !== "get") {
        // if (config.data) { 
        //     config.data.token = token;
        // }
        config.data = config.data && Qs.stringify(config.data);
    }
    if (!config.params) config.params = {
        token
    }
    config.params.token = token;
    return config
}, err => {
    console.log(err.message)
})
http.interceptors.response.use(res => {
    let code = res.data.code;
    switch (code) {
        case 401:
            // this_.$msg.message({
            //     icon: 'tips',
            //     content: `登录失效，请重新登录`,
            //     success() {
            this_.$router.push('/login')
            //     }
            // });
            break
    }
    return res
}, err => {
    if (err.response) {
        let status = err.response.status;
        let code = err.response.data.code;
        console.log(`status:${err.response.status}`);
        // 网络出现500 或 400
        if (/^50[0-9]/.test(code)) {
            const erMsg = err.response.data.message;
            const content = erMsg || `请求出错啦！`;
            // 服务器错误 
            this_.$msg.message({
                icon: 'warning',
                content: `${content}`,
            });
        }
        if (/^4[0-1][0-9]/.test(code)) {}
        return Promise.reject(err.response)
    }
    return Promise.reject(err.response)
})