<template>
  <div class="share-page">
    <el-form
      class="shareForm"
      ref="shareForm"
      :model="shareForm"
      :rules="shareFormRules"
      :labelPosition="labelPosition"
      label-width="120px"
    >
      <el-form-item label="存证编号:" prop="noId">
        <el-input
          placeholder="请输入唯一的存证编号"
          v-model="shareForm.noId"
        ></el-input>
      </el-form-item>
      <el-form-item label="授权验证码:" prop="code">
        <el-input
          placeholder="请输入授权验证码"
          v-model="shareForm.code"
        ></el-input>
      </el-form-item>
    </el-form>

    <div class="formFooterSumit">
      <button class="submitVeirify" @click="submitVeri">验证</button>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { verifyShare } from "@/network/verification.js";
export default {
  data() {
    return {
      shareForm: {
        code: "",
        noId: "",
      },
      shareFormRules: {
        noId: [
          { required: true, message: "请填写证书编号", trigger: "blur" },
          {
            min: 24,
            max: 24,
            message: "请输入24位的存证编号",
            trigger: "blur",
          },
        ],
        code: [
          { required: true, message: "请填写授权验证码", trigger: "blur" },
        ],
      },
    };
  },
  props: {
    labelPosition: {
      type: String,
      default: () => "left",
    },
  },
  mounted() {},
  methods: {
    async submitVeri() {
      await this.$refs.shareForm.validate();
      // const { data: res } = verifyShare(this.shareForm);
      const { data: res } = await verifyShare({ ...this.shareForm });
      let comName = "fail_ver";
      console.log(res.code, "查询");
      if (res.code === 200) {
        this.$message.success("验证成功！");
        comName = "ShareSuccess";
        this.$emit("comChange", { comName, successData: res.data });
      } else {
        this.$message.error("验证失败！");
        this.$emit("comChange", { comName });
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .share-page {
    width: 710px;
  }
}

.share-page {
  margin: 100px auto 200px;
  .formFooterSumit {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .submitVeirify {
      width: 200px;
      height: 40px;
      background-color: #3d6df1;
      color: #fff;
      border-radius: 5px;
      margin: 80px auto;
    }
  }
}

@media screen and(max-width:768px) {
  .share-page {
    margin: 50px 0;
    padding: 0 30px;
  }
}
</style>