import {
  http
} from './concrolRequest.js'

// 分享查询
export const verifyShare = (params) => {
  return http({
    url: '/api/account/verify-evidence-share',
    params,
    method: 'post'
  })
}

//验证存证
export const checkEvidence = (params) => {
  return http({
    url: '/api/account/check-evidence',
    params,
    method: 'post'
  })
}